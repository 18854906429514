const EVENT_NAMES = {
  EXTENSION_INIT: 'extension_init',
  INSTALLED_EXTENSION: 'installed_extension',
  UNINSTALL_EXTENSION: 'uninstall_extension',
  PAGE_VIEW: 'page_view',
  CLICK: 'click',
  IMPRESSION: 'impression',
};

export default EVENT_NAMES;
