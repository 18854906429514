function sortObject(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((accumulator, key) => {
      accumulator[key] = obj[key];

      return accumulator;
    }, {});
}

/**
 * Join objects and convert it to string
 *
 * @param {object} args - objects to join
 * @return {string|undefined} - joined objects as string or undefined
 */
function joinNonStandard(...args) {
  const nonStandards = args.filter(arg => arg && typeof arg === 'object' && !Array.isArray(arg));
  const nonStandard = Object.assign({}, ...nonStandards);

  return nonStandard && Object.keys(nonStandard).length ? JSON.stringify(sortObject(nonStandard)) : undefined;
}

export default joinNonStandard;
