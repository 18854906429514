import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import aloomaSdk from './aloomaSdk';
import { ALOOMA_PATH, ALOOMA_TOKEN } from './consts/alooma';
import './index.css';
import App from './App';

function initAlooma() {
  if (globalThis.alooma) return;

  aloomaSdk.init(ALOOMA_PATH, ALOOMA_TOKEN);
}

initAlooma();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
