const ATTRS = {
  PAGE_NAME: 'data-alooma-page-name',
  DISABLED: 'data-alooma-disabled',
  ELEMENT: 'data-alooma-element',
  MODAL_TYPE: 'data-alooma-modal',
  MODAL_NAME: 'data-alooma-modal-name',
  NON_STANDARD: 'data-alooma-non-standard',
};

export default ATTRS;
