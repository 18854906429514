import sendEvent from './methods/sendEvent';
import init from './methods/init';
import useClicksTracker from './utils/useClicksTracker';
import useContextDataAttrs from './utils/useContextDataAttrs';
import useImpressionTracker from './utils/useImpressionTracker';
import usePageViewTracker from './utils/usePageViewTracker';
import {
  ModalNameContextProvider,
  ModalTypeContextProvider,
  NonStandardDataContextProvider,
  PageNameContextProvider,
} from './context';
import ATTRS from './constants/attributes';
import EVENT_NAMES from './constants/eventNames';
import PARAMS from './constants/params';

const aloomaSdk = {
  // Methods:
  init,
  sendEvent,

  // React hooks:
  useClicksTracker,
  useContextDataAttrs,
  useImpressionTracker,
  usePageViewTracker,

  // React contexts:
  PageNameContextProvider,
  ModalTypeContextProvider,
  ModalNameContextProvider,
  NonStandardDataContextProvider,

  // Constants:
  ATTRS,
  EVENT_NAMES,
  PARAMS,
};

export default aloomaSdk;
