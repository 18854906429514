import React, { useContext, useMemo } from 'react';

export const PageNameContext = React.createContext();
export const ModalTypeContext = React.createContext();
export const ModalNameContext = React.createContext();
export const NonStandardDataContext = React.createContext();

export function PageNameContextProvider({ value, children }) {
  return <PageNameContext.Provider value={value}>{children}</PageNameContext.Provider>;
}

export function ModalTypeContextProvider({ value, children }) {
  return <ModalTypeContext.Provider value={value}>{children}</ModalTypeContext.Provider>;
}

export function ModalNameContextProvider({ value, children }) {
  return <ModalNameContext.Provider value={value}>{children}</ModalNameContext.Provider>;
}

export function NonStandardDataContextProvider({ value, children }) {
  const nonStandardData = useContext(NonStandardDataContext);
  const newNonStandardData = useMemo(() => (nonStandardData ? { ...nonStandardData, ...value } : value), [value]);

  return <NonStandardDataContext.Provider value={newNonStandardData}>{children}</NonStandardDataContext.Provider>;
}
