import { useContext, useEffect } from 'react';
import { NonStandardDataContext, PageNameContext } from '../context';
import EVENT_NAMES from '../constants/eventNames';
import PARAMS from '../constants/params';
import joinNonStandard from './joinNonStandard';

/**
 * Fire "page_view" alooma event
 *
 * @param {object} [extraNonStandardData] - extra non-standard data
 * @param {Boolean} [condition] - condition to fire event
 */
const usePageViewTracker = (extraNonStandardData, condition = true) => {
  const pageName = useContext(PageNameContext);
  const contextNonStandardData = useContext(NonStandardDataContext);
  const nonStandardString = joinNonStandard(contextNonStandardData, extraNonStandardData);

  useEffect(() => {
    if (!pageName || !condition || !globalThis?.alooma?.track) {
      return;
    }

    const nonStandard = nonStandardString ? JSON.parse(nonStandardString) : {};
    const data = { ...nonStandard, [PARAMS.PAGE_NAME]: pageName };

    globalThis.alooma.track(EVENT_NAMES.PAGE_VIEW, data);
  }, [condition, pageName, nonStandardString]);
};

export default usePageViewTracker;
