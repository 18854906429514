import { useContext, useEffect } from 'react';
import { ModalNameContext, ModalTypeContext, NonStandardDataContext, PageNameContext } from '../context';

import EVENT_NAMES from '../constants/eventNames';
import PARAMS from '../constants/params';
import joinNonStandard from './joinNonStandard';

/**
 * Fire "impression" alooma event
 *
 * @param {object} [extraNonStandardData] - extra non-standard data
 * @param {boolean} [condition] - condition to fire event
 */
const useImpressionTracker = (extraNonStandardData, condition = true) => {
  const pageName = useContext(PageNameContext);
  const modalType = useContext(ModalTypeContext);
  const modalName = useContext(ModalNameContext);
  const contextNonStandardData = useContext(NonStandardDataContext);
  const nonStandardString = joinNonStandard(contextNonStandardData, extraNonStandardData);

  useEffect(() => {
    if (!condition || !pageName || !modalType || !modalName || !globalThis?.alooma?.track) {
      return;
    }

    const nonStandard = nonStandardString ? JSON.parse(nonStandardString) : {};
    const data = {
      ...nonStandard,
      [PARAMS.PAGE_NAME]: pageName,
      [PARAMS.MODAL_TYPE]: modalType,
      [PARAMS.MODAL_NAME]: modalName,
    };

    globalThis.alooma.track(EVENT_NAMES.IMPRESSION, data);
  }, [condition, pageName, modalType, modalName, nonStandardString]);
};

export default useImpressionTracker;
