import { useLayoutEffect, useState } from 'react';
import aloomaSdk from './aloomaSdk';
import UninstallForm from './components/UninstallForm/UninstallForm';
import { ALOOMA_PAGE_NAMES } from './consts/alooma';

const App = () => {
  const [ref, setRef] = useState(null);
  const clicksTracker = aloomaSdk.useClicksTracker();

  useLayoutEffect(() => {
    if (!ref) return;

    clicksTracker(ref);
  }, [ref]);

  return (
    <div className="app-container" ref={setRef}>
      <aloomaSdk.NonStandardDataContextProvider
        value={{
          user_id: 16,
          component: 'm3u8-downloader-extension',
        }}
      >
        <aloomaSdk.PageNameContextProvider value={ALOOMA_PAGE_NAMES.extension_uninstall}>
          <UninstallForm />
        </aloomaSdk.PageNameContextProvider>
      </aloomaSdk.NonStandardDataContextProvider>
    </div>
  );
};

export default App;
