import sendEvent from './sendEvent';

/**
 * @typedef {Object} Alooma
 * @property {string} _token - alooma token
 * @property {string} _path - alooma path
 * @property {function(name: string, data: object): Promise<void>} track - send event to alooma
 */

/**
 * Initialize alooma variable
 *
 * @param {string} path - alooma path
 * @param {string} token - alooma token
 * @returns {Alooma} alooma variable
 */
const init = (path, token) => {
  globalThis.alooma = {
    _token: token,
    _path: path,
    track: sendEvent.bind(null, path, token),
  };

  return globalThis.alooma;
};

export default init;
