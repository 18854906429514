import { useContext, useMemo } from 'react';
import { ModalNameContext, ModalTypeContext, NonStandardDataContext, PageNameContext } from '../context';
import ATTRS from '../constants/attributes';
import joinNonStandard from './joinNonStandard';

/**
 * @typedef {object} ClickElementAttrs
 * @property {string} [pageName] - page name
 * @property {string} [element] - element name
 * @property {string} [modalType] - modal type
 * @property {string} [modalName] - modal name
 * @property {string} [nonStandard] - non-standard data
 */

/**
 * Get the context data for clickable elements
 *
 * @param {string} element - element name
 * @param {object} [extraNonStandardData] - extra non-standard data
 * @return {ClickElementAttrs} - attributes for clickable elements
 */
const useContextDataAttrs = (element, extraNonStandardData) => {
  const pageName = useContext(PageNameContext);
  const modalType = useContext(ModalTypeContext);
  const modalName = useContext(ModalNameContext);
  const contextNonStandardData = useContext(NonStandardDataContext);
  const nonStandardString = joinNonStandard(contextNonStandardData, extraNonStandardData);

  return useMemo(
    () => ({
      [ATTRS.PAGE_NAME]: pageName,
      [ATTRS.ELEMENT]: element,
      [ATTRS.MODAL_TYPE]: modalType,
      [ATTRS.MODAL_NAME]: modalName,
      [ATTRS.NON_STANDARD]: nonStandardString,
    }),
    [pageName, element, modalType, modalName, nonStandardString],
  );
};

export default useContextDataAttrs;
