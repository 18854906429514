export const ALOOMA_PATH = 'https://event-tracking-lambda-va5mbfrpbq-ue.a.run.app';
export const ALOOMA_TOKEN = 'sBGUbZm3hp';

export const ALOOMA_ELEMENT_NAMES = {
  SUBMIT: 'submit',
};

export const ALOOMA_PAGE_NAMES = {
  extension_uninstall: 'extension_uninstall',
};
