import { useCallback, useEffect, useRef } from 'react';
import ATTRS from '../constants/attributes';
import PARAMS from '../constants/params';
import EVENT_NAMES from '../constants/eventNames';

const clickHandler = event => {
  const path = event.path || (event.composedPath && event.composedPath());
  const origin =
    path && path.find(el => el.getAttribute && el.getAttribute(ATTRS.ELEMENT) && el.getAttribute(ATTRS.PAGE_NAME));

  if (!origin || origin.getAttribute(ATTRS.DISABLED) === 'true' || !globalThis?.alooma?.track) {
    return;
  }

  const notStandardAttrsString = origin.getAttribute(ATTRS.NON_STANDARD);
  const notStandardAttrs = (notStandardAttrsString && JSON.parse(notStandardAttrsString)) || {};

  const params = {
    ...notStandardAttrs,
    [PARAMS.ELEMENT]: origin.getAttribute(ATTRS.ELEMENT),
    [PARAMS.PAGE_NAME]: origin.getAttribute(ATTRS.PAGE_NAME),
    [PARAMS.MODAL_TYPE]: origin.getAttribute(ATTRS.MODAL_TYPE),
    [PARAMS.MODAL_NAME]: origin.getAttribute(ATTRS.MODAL_NAME),
  };

  globalThis.alooma.track(EVENT_NAMES.CLICK, params);
};

/**
 * Create a click event listener
 *
 * @return {(function(node: HTMLElement): void)}
 */
const useClicksTracker = () => {
  const ref = useRef(null);

  useEffect(
    () => () => {
      if (ref.current) {
        ref.current.removeEventListener('click', clickHandler, { capture: true });
      }
    },
    [],
  );

  return useCallback(node => {
    if (ref.current) {
      ref.current.removeEventListener('click', clickHandler, { capture: true });
    }

    if (node) {
      node.addEventListener('click', clickHandler, { capture: true });
    }

    ref.current = node;
  }, []);
};

export default useClicksTracker;
