/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo, useState } from 'react';
import random from 'lodash/random';
import classnames from 'classnames';
import './UninstallForm.css';
import aloomaSdk from '../../aloomaSdk';
import { ALOOMA_ELEMENT_NAMES, ALOOMA_PAGE_NAMES } from '../../consts/alooma';

const REASONS = {
  DID_NOT_UNDERSTAND_HOW_TO_USE_IT: {
    key: 'DID_NOT_UNDERSTAND_HOW_TO_USE_IT',
    label: 'Did not understand how to use it',
    value: 1,
    order: random(1, 99),
  },
  FOUND_A_BETTER_TOOL: {
    key: 'FOUND_A_BETTER_TOOL',
    label: 'Found a better tool',
    value: 2,
    order: random(1, 99),
  },
  TOO_SLOW: {
    key: 'TOO_SLOW',
    label: 'Too slow',
    value: 3,
    order: random(1, 99),
  },
  DATA_PRIVACY_CONCERNS: {
    key: 'DATA_PRIVACY_CONCERNS',
    label: 'Data privacy concerns',
    value: 4,
    order: random(1, 99),
  },
  DID_NOT_WORK_AS_EXPECTED: {
    key: 'DID_NOT_WORK_AS_EXPECTED',
    label: 'Did not work as expected',
    value: 5,
    order: random(1, 99),
  },
  CAUSED_PROBLEMS_WITH_MY_BROWSER: {
    key: 'CAUSED_PROBLEMS_WITH_MY_BROWSER',
    label: 'Caused problems with my browser',
    value: 6,
    order: random(1, 99),
  },
  OTHER: {
    key: 'OTHER',
    label: 'Other',
    value: 7,
    order: 100,
  },
};

const values = Object.values(REASONS);

const defaultReasonsValues = values.reduce(
  (previousValue, currentValue) => ({
    ...previousValue,
    [currentValue.key]: false,
  }),
  {},
);

const UninstallForm = () => {
  const [sent, setSent] = useState(false);
  const [reasons, setReasons] = useState(defaultReasonsValues);
  const [additionalFeedback, setAdditionalFeedback] = useState('');

  const showMoreReason = useMemo(() => Object.values(reasons).some(value => value), [reasons]);

  const contextDataAttrs = aloomaSdk.useContextDataAttrs();

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;

    setReasons(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSubmit = async event => {
    try {
      event.preventDefault();

      const selectedReasons = Object.keys(reasons)
        .filter(reason => reasons[reason])
        .map(reason => REASONS[reason].value)
        .join(',');

      if (selectedReasons.length === 0) {
        return;
      }

      const payload = {
        user_id: 16,
        element_name: ALOOMA_ELEMENT_NAMES.SUBMIT,
        component: 'm3u8-downloader-extension',
        page_name: ALOOMA_PAGE_NAMES.extension_uninstall,
        reasons_codes: selectedReasons,
        reason: additionalFeedback,
      };

      globalThis.alooma.track('click', payload);

      setSent(true);
    } catch (error) {
      //
    }
  };

  aloomaSdk.usePageViewTracker();

  return (
    <form className="container" onSubmit={handleSubmit}>
      <img className="sad-icon" src="/icons/sad.svg" alt="Sad face" />
      <h1>We’re sorry to see you go</h1>
      <p>Tell us why you uninstalled the extension</p>

      <div className="reasons">
        {values.map(({ key, order, label }) => (
          <label
            className={classnames('reason-item', {
              disabled: sent,
              active: reasons[key],
            })}
            htmlFor={key}
            key={key}
            style={{ order }}
          >
            <input
              type="checkbox"
              id={key}
              name={key}
              onChange={handleCheckboxChange}
              disabled={sent}
              {...contextDataAttrs}
              data-alooma-element={key}
            />
            {label}
          </label>
        ))}
      </div>

      {showMoreReason && (
        <div className="additional-feedback">
          <label
            htmlFor="additionalFeedback"
            className={classnames({
              disabled: sent,
            })}
          >
            Tell us more (optional):
          </label>
          <textarea
            id="additionalFeedback"
            value={additionalFeedback}
            onChange={e => setAdditionalFeedback(e.target.value)}
            disabled={sent}
          />
        </div>
      )}

      <button className="submit-btn" disabled={sent || !showMoreReason} type="submit">
        {sent ? 'Sent' : 'Send'}
        {sent && <img className="submitted-icon" src="/icons/checkmark.svg" alt="Check icon" />}
      </button>
    </form>
  );
};

export default UninstallForm;
