import axios from 'axios';
import buildUrl from '../utils/buildUrl';

async function buildEvent(token, name, data = {}) {
  const event = {
    ...data,
    event: name,
    token,
    local_time: new Date().toString(),
  };

  return Object.entries(event).map(([key, value]) => ({ name: key, value }));
}

/**
 * Send event to alooma
 *
 * @param {string} path - path to alooma
 * @param {string} token - alooma token
 * @param {string} name - event name
 * @param {object} data - event data
 * @return {Promise<void>}
 */
async function sendEvent(path, token, name, data) {
  if (!path || !token || !name) {
    return;
  }

  const event = await buildEvent(token, name, data);
  const url = buildUrl(path, event);

  await axios.get(url).catch(error => {
    if (process.env.NODE_ENV !== 'production') console.error(error);
  });
}

export default sendEvent;
